<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="setRelocateItems">

      <div class="row">
        <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('new_responsible_id') }">
          <div class="form-group">

            <label for="new_responsible_id" class="font-medium font-small">
              Новый ответственный
            </label>
            <multiselect :allow-empty="false"
                         :options="employees"
                         :searchable="false"
                         id="new_responsible_id"
                         @input="() => {
                               form.new_responsible_id = responsible ? responsible.id : null;
                             }"
                         deselect-label="Убрать"
                         label="title"
                         placeholder="Новый ответственный"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Новый ответственный"
                         track-by="id"
                         v-model="responsible">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
            </multiselect>
            <HasError :form="form" field="new_responsible_id" />
          </div>
        </div>
      </div>

      <br />

      <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

      <Button :disabled="form.busy"
              :form="form"
              :loading="form.busy"
              class="btn btn-lg btn-success pull-right"
              style="min-width: 100px;">
        <i class="fa fa-save"></i>
      </Button>
    </form>
  </transition>
</template>

<script>
import Form from "vform";
import Multiselect from "vue-multiselect";

Form.axios = API.apiClient;

export default {
  name: "set-relocate-items-form",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    employee_id: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    employees: [],
    responsible: null,

    form: new Form({
      new_responsible_id: null,
    }),
  }),

  async created() {
    try {
      await this.getEmployees();

    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {

  },

  methods: {
    async getEmployees() {
      try {
        const clients_response = await API.apiClient.get("/employees", {
          params: {
            is_paginated: 0,
          }
        });
        this.employees = clients_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async setRelocateItems() {
      await this.form.put("/employees/relocate_items/" + this.employee_id).then(() => {
        showSuccess();
        this.EventBus.$emit("hide-quick-create-client-modal");
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style>

</style>
